import React, { memo, MouseEvent, useCallback, useMemo, useRef } from 'react'
import { IOrderDocumentLink, IOrderDocumentType, IOrderType } from '../../../services/api/service/orders/types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Button from '../../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { ordersDocumentProcessAction, ordersDocumentResetSingleAction } from '../../../store/orders/actions'
import {
    makeSelectOrdersDocumentError,
    makeSelectOrdersDocumentFetching,
    makeSelectOrdersDocumentLink,
} from '../../../store/orders/selectors'
import { IApplicationRootState } from '../../../store'
import { IAppErrorTypes } from '../../../store/app/types'
import SweetAlert from 'react-bootstrap-sweetalert'
import RemotePdf from '../../../components/RemotePdf/RemotePdf'
import { OrderDetailAction } from './type'
import isUndefined from 'lodash/isUndefined'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Nullable } from 'tsdef'
import isNull from 'lodash/isNull'
import Portal from '../../../components/Portal/Portal'
import { getDocumentIcon } from './utils'
import ApplicationHelper from '../../../utils/applicationHelper'

type Props = OrderDetailAction & {
    type: IOrderDocumentType
    tooltip?: string | null
    orderId: string
    orderNumber: string
    orderType?: IOrderType
    onClick?: (e: MouseEvent<HTMLButtonElement>, orderId: string, type: IOrderDocumentType) => void
}

const Document = ({
    orderId,
    orderNumber,
    orderType = IOrderType.Order,
    type,
    onClick,
    icon,
    label,
    size,
    block,
    tooltip,
    variant = 'link',
}: Props): JSX.Element => {
    const dispatch = useDispatch()
    const targetBtn = useRef<HTMLButtonElement>()
    const { formatMessage } = useIntl()

    const isTouchScreen = ApplicationHelper.isTouchScreen()
    const selectFetching = useMemo(makeSelectOrdersDocumentFetching, [])
    const selectLink = useMemo(makeSelectOrdersDocumentLink, [])
    const selectError = useMemo(makeSelectOrdersDocumentError, [])
    const fetching: boolean = useSelector<IApplicationRootState, boolean>((state) =>
        selectFetching(state, orderId, type)
    )
    const link: IOrderDocumentLink | undefined = useSelector<IApplicationRootState, IOrderDocumentLink | undefined>(
        (state) => selectLink(state, orderId, type)
    )
    const error: IAppErrorTypes | undefined = useSelector<IApplicationRootState, IAppErrorTypes | undefined>((state) =>
        selectError(state, orderId, type)
    )

    const lbl = useMemo(() => {
        if (isUndefined(label)) {
            return formatMessage({ id: `${orderType}_document.${type}` })
        }
        return label
    }, [label, formatMessage, type])

    const icn: Nullable<IconProp> = useMemo(() => {
        if (isUndefined(icon)) {
            return getDocumentIcon(type)
        }
        return icon
    }, [icon, type])

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            e.stopPropagation()

            if (onClick) {
                onClick(e, orderId, type)
            }

            dispatch(ordersDocumentProcessAction(orderId, type))
        },
        [dispatch, onClick, orderId, type]
    )

    const handleConfirmError = useCallback(() => {
        dispatch(ordersDocumentResetSingleAction(orderId, type))
    }, [dispatch, orderId, type])

    const handleRemotePdfClose = useCallback(() => {
        dispatch(ordersDocumentResetSingleAction(orderId, type))
    }, [dispatch, orderId, type])

    const tooltipMessage = useMemo(() => {
        if (isUndefined(tooltip)) {
            return formatMessage({ id: `${orderType}_document.detail_${type}` })
        }
        return tooltip
    }, [type, orderType, tooltip, formatMessage])

    const ActionButtonComp = (
        <Button ref={targetBtn} variant={variant} onClick={handleClick} disabled={fetching} size={size} block={block}>
            {icn && <FontAwesomeIcon icon={icn} />}
            {lbl && <span className="lbl">{lbl}</span>}
        </Button>
    )

    return (
        <>
            {isTouchScreen ? (
                <>{ActionButtonComp}</>
            ) : (
                <OverlayTrigger
                    show={isNull(tooltipMessage) ? false : undefined}
                    overlay={<Tooltip id={`tooltip-${orderId}-${type}`}>{tooltipMessage}</Tooltip>}
                >
                    {ActionButtonComp}
                </OverlayTrigger>
            )}
            <SweetAlert
                error
                title={formatMessage({
                    id: 'default.error',
                })}
                show={typeof error !== 'undefined'}
                onConfirm={handleConfirmError}
            >
                {error?.message}
            </SweetAlert>
            {link && type === IOrderDocumentType.PurchaseOrderPdf && (
                <RemotePdf title={orderNumber} pdf={link} onExit={handleRemotePdfClose} />
            )}
            {link &&
                [
                    IOrderDocumentType.DeliveryNoteExcel,
                    IOrderDocumentType.PurchaseOrderExcel,
                    IOrderDocumentType.DeliveryRemaindersExcel,
                ].indexOf(type) > -1 && (
                    <Portal>
                        <iframe src={link.url} width={0} height={0} style={{ border: '0 none' }} />
                    </Portal>
                )}
        </>
    )
}

export default memo(Document)
